import { IInformation, Information } from "@/model/Information";
import { Document } from "@/model/Document";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IInformationGroup {
    id: string;
    group: string;
    information: Array<IInformation>;
}

export class InformationGroup implements IInformationGroup {

    constructor(data?: IInformationGroup) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IInformationGroup) {
        mapData(data, { 
            root: () => this,
            information: () => new Information(),
            documents: () => new Document()
        });
    }

    id: string = utils.emptyGuidValue;
    group: string = "";
    information: Array<Information> = [];
    
    active: boolean = true;

}