




























































import Vue from "vue";
import Component from "vue-class-component";
import { IInformationGroup, InformationGroup } from "@/model/InformationGroup";
import { Document } from "@/model/Document";
import apiClient from "@/utilities/ApiClient";
import fileDownload from "@/utilities/FileDownload";

@Component({ })
export default class Info extends Vue {

    async mounted() {
        const getInfo = async () => { await this.getInfo(); };
        await Promise.all([ 
            getInfo() 
        ]);
    }
    
    //
    // -- properties
    //

    groups: Array<InformationGroup> = [];

    //
    // -- methods
    //

    private async getInfo() {
        this.groups = [];
        const serverData: Array<IInformationGroup> = await apiClient.get("/api/information/info");
        this.groups.push(...serverData.map(g => new InformationGroup(g)));
    }

    private async downloadInfoDoc(document: Document) {
        await fileDownload.download("/api/information/download", { documentID: document.id } , `${document.description}${document.extension}`)
    }

}
