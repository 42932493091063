import apiClient from "@/utilities/ApiClient";
import * as toastr from "toastr";

module FileDownload {

    // We cannot just use the GET URL to the file in an href because
    // the server needs the authentication token in the header!  So 
    // we have to download it and create a dynamic link.  There are
    // two ways of doing this: base64 and createObjectURL:
    export async function download (path: string, request: any, filename: string) {
        const blob: Blob = await apiClient.post(path, request);

        if(!(blob instanceof Blob)) {
            toastr.error("Unexpected data from server", "Download failed");
            return;
        }
        const maxSizeForBase64 = 1024 * 100; // not sure if this is really needed?
        const anchor = document.createElement("a");
        const windowUrl = window.URL || window.webkitURL;
        if (blob.size > maxSizeForBase64 && typeof windowUrl.createObjectURL === "function") {
            console.log("### using createObjectURL");
            const fileUrl = windowUrl.createObjectURL(blob);
            anchor.download = filename;
            anchor.href = fileUrl;
            anchor.click();
            windowUrl.revokeObjectURL(fileUrl);
        }
        else {
            //use base64 encoding when less than set limit or file API is not available
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                anchor.download = filename;
                if(typeof reader.result !== "string") {
                    toastr.error("Download failed");
                    return;
                }
                anchor.href = reader.result;
                anchor.click();
            }
        }
    }

}

export default FileDownload;