import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IDocument {
    id: string;
    description: string;
    extension: string;
}

export class Document implements IDocument {

    constructor(data?: IDocument) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IDocument) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    description: string = "";
    extension: string = "";

}