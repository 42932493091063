
import { IDocument, Document } from "@/model/Document";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IInformation {
    id: string;
    title: string;
    content: string;
    documents: Array<IDocument>;
}

export class Information implements IInformation {

    constructor(data?: IInformation) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IInformation) {
        mapData(data, { 
            root: () => this,
            documents: () => new Document()
        });
    }

    id: string = utils.emptyGuidValue;
    title: string = "";
    content: string = "";
    documents: Array<Document> = [];
    
    active: boolean = true;

}